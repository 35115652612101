.body-container {
    display: grid;
}

h1 {
    margin: 0;
}

body {
    margin: 0;
    background-color: #f4f4f4;
    /* height: 5000px; */
    
}

p {
    margin: 0;
    padding: 0;
}

section {
    display: grid;
    /* grid-template-columns: 1fr; */
}

.container {
    margin: 200px 0;
}

.title {
    font-weight: bold;
    font-size: 30px;
    color: orange;
    margin-bottom: 20px;
}

.primary-text {
    font-size: 20px;
    font-weight: 800;
}

.secondary-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: rgb(79, 79, 79);
    /* margin-bottom: 20px; */
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}