#contact {
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px;

    .container {
        max-width: 970px;
        box-sizing: border-box;
    }

    .title {
        margin-bottom: 20px;
        text-align: center;
    }

    .content-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 80px;
    }

    .email-content,
    .linkedin-content,
    .github-content {
        display: flex;
        align-items: center;

        p {
            margin: 0;
            transition: .2s;
        }
        
        p.secondary-text:hover {
            color: orange;
        }
    }

    .icon-container {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 20px;
        // border: 1px solid black;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        color: orange;
    }

    .icon-email,
    .icon-linkedin,
    .icon-github {
        font-size: 30px;
    }

    .primary-text {
        margin-bottom: 5px !important;
    }

    @media (width < 900px) {
        .container {
            padding: 0 25px;;
            margin: 60px 0;
            justify-content: center;
        }
        
        .content-container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }
}