#about {
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;

    .container {
        // margin: 200px 0;
        max-width: 970px;
        display: flex;
        gap: 80px;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
    }
    
    .img-about {
        width: 350px;
        border-radius: 50px;
    }
    
    .secondary-text {
        line-height: 1.6;
        font-weight: 500;
        font-size: 17px;
        height: 100%;
    }

    @media (width < 900px) {
        .container {
            // max-width: 400px;
            display: grid;
            margin: 60px 0;
            place-items: center;
        }

        .img-about {
            // width: 90%;
            // height: 30%;
            max-width: 400px;
            max-height: 280px;
            // width: 100%;
            object-fit: cover;
            object-position: center bottom;
            // margin-bottom: 10px;
        }

        .title {
            text-align: center;
        }

        .secondary-text {
            text-align: center;
        }
    }
}