#home {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .container {
        max-width: 970px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    
    .hero {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    .greeting {
        font-size: 50px;
        font-weight: 800;
        margin-bottom: 20px;
    }
    
    .hand {
        width: 50px;
    }

    .secondary-text {
        margin-bottom: 20px;
    }
    
    .bio-actions {
        display: flex;
        align-items: center;;
    }
    
    .resume-download {
        background-color: rgb(79, 79, 79);
        border: 1px solid grey;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 20px;
        transition: .3s;
        cursor: pointer;
        color: white;
    }
    
    .resume-download:hover {
        background-color: rgba(255, 166, 0, 1);
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        color: black;
    }
    
    .resume-download:active {
        background-color: rgba(255, 166, 0, 0.8);
    }
    
    .linkedin {
        font-size: 43px;
        color: rgb(79, 79, 79);
        transition: .3s;
        cursor: pointer;
    }
    
    .linkedin:hover {
        color: #0A66C2;
    }
    
    .linkedin:active {
        color: #0a66c2dc;
    }
    
    .avatar {
        object-fit: cover;
        height: 400px;
        // width: 350px;
        border-radius: 50%;
    }

    @media (max-width: 800px) {    
        .container {
            width: 100%;
            margin: 130px 20px;
        }

        .hero {
            flex-direction: column-reverse;
        }

        .greeting {
            font-size: 36px;
            text-align: center;
        }

        .hand {
            width: 40px;
        }

        .secondary-text {
            text-align: center;
        }

        .bio-actions {
            display: flex;
            justify-content: center;
        }

        .avatar {
            height: 300px;
        }
    }
}