footer {
    background-color: rgb(79, 79, 79);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    
    .container {
        max-width: 970px;
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .footer-text {
        font-weight: 500;
        font-size: 14px;
        color: rgb(164, 164, 164);
        display: flex;
    }
}