.project-container {
    background-color: #f8f8f8;
    max-width: 970px;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    padding: 20px;
    // display: grid;
    // grid-template-columns: 1.5fr 1fr;
    display: flex;
    box-sizing: border-box;
    gap: 25px;
    margin-bottom: 20px;

    .image {
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(0,0,0,.5);
        width: 100%;
        // max-width: 100%;
        flex: 1;
        max-height: 330px;
        // background-color: black;
        object-fit: cover;
        object-position: 50% 0%;
        aspect-ratio : 1 / 1;
        // width:50%;
    }

    .right-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .primary-text {
        margin-bottom: 20px;
    }

    .primary-text,
    .secondary-text {
        text-align: center;
    }

    .proj-tech {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .tech-cont {
        width: 100px;
        // border: 1px solid black;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        padding: 8px 8px;
        box-shadow: 0 0 8px rgba(0,0,0,.2);
        // margin-right: 10px;
        font-size: 18px;
    }

    .tech-cont:not(:first-of-type) {
        margin-left: 20px;
    }

    .icon-react,
    .icon-typescript {
        transition: .2s;
    }

    .icon-react:hover {
        // transition: .9;
        color: #61DBFB;
    }

    .icon-typescript:hover {
        color: #007acc;
    }

    .proj-title,
    .proj-desc,
    .proj-links {
        display: flex;
        justify-content: center;
    }

    .proj-links {
        padding: 10px 20px;
        margin-top: 20px;
        gap: 20px;
    }

    .action-container{
        display: flex;
        align-items: center;
        font-size: 32px;
        transition: .2s;
    }

    .action-container:hover {
        color: orange;
    }

    .proj-action {
        font-size: 20px;
        font-weight: 500;
    }

    .btn-code {
        margin-right: 15px;
    }

    .btn-code,
    .btn-demo {
        padding: 6px 20px;
        background-color: rgb(79, 79, 79);
        border: 1px solid grey;
        font-size: 16px;
        font-weight: 600;
        border-radius: 20px;
        transition: .3s;
        cursor: pointer;
        color: white;
    }

    .btn-code:hover,
    .btn-demo:hover {
        background-color: rgba(255, 166, 0, 1);
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        color: black;
    }

    // @media (width < 900px) {
    //     flex-direction: column;
    //     flex: initial;
    // }
}

.project-container:nth-child(even) {
    flex-direction: row-reverse;
    display: flex;

    // @media (width < 900px) {
    //     flex-direction: column;
    //     flex: initial;
    // }
}

.project-container,
.project-container:nth-child(even) {
    @media (width < 900px) {
        flex-direction: column;
        flex: initial;
    }
}