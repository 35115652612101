#portfolio {
    display: flex;
    flex-direction: column;

    .container {
        // margin: 200px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 20px;
    }
    

    @media (width < 900px) {

        .container {
            margin: 60px 0;
        }
    }
}